import React, {lazy, Suspense} from "react"
import {Redirect, Route, Switch} from "react-router"
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader"

const Main = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, "")

    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/home`}/>
                <Route
                    path={`${requestedUrl}/home`}
                    component={lazy(() => import('./Home'))}
                />
                <Route
                    path={`${requestedUrl}/bedrijvenzoeker`}
                    component={lazy(() => import("./BusinessFinder"))}
                />
                <Route
                    path={`${requestedUrl}/settings`}
                    component={lazy(() => import("./Settings"))}
                />
                <Route
                    path={`${requestedUrl}/checkout`}
                    component={lazy(() => import("./StripeCheckout"))}
                />
                <Route
                    path={`${requestedUrl}/payment`}
                    component={lazy(() => import("./PaymentSuccessful"))}
                />
                <Route component={lazy(() => import('../ExtraPages/404'))}/>
            </Switch>
        </Suspense>
    )
}

export default Main
