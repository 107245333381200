import React from "react"

// Local Imports
import StripePaymentContext from "./StripePaymentContext"

const StripePaymentContextProvider = ({children}) => {
    const [localSEOStripeSessionId, setLocalSEOStripeSessionId] = React.useState(null)

    return (
        <StripePaymentContext.Provider
            value={{
                localSEOStripeSessionId,
                setLocalSEOStripeSessionId
            }}
        >
            {children}
        </StripePaymentContext.Provider>
    )
}

export default StripePaymentContextProvider
